import { render, staticRenderFns } from "./TabColFacs.vue?vue&type=template&id=6da1b018&scoped=true"
import script from "./TabColFacs.vue?vue&type=script&lang=js"
export * from "./TabColFacs.vue?vue&type=script&lang=js"
import style0 from "./TabColFacs.vue?vue&type=style&index=0&id=6da1b018&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6da1b018",
  null
  
)

export default component.exports