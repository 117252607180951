import { render, staticRenderFns } from "./ZoneComponent.vue?vue&type=template&id=2442c831&scoped=true"
import script from "./ZoneComponent.vue?vue&type=script&lang=js"
export * from "./ZoneComponent.vue?vue&type=script&lang=js"
import style0 from "./ZoneComponent.vue?vue&type=style&index=0&id=2442c831&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2442c831",
  null
  
)

export default component.exports